module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rossini Pizzeria","short_name":"Rossini restaurant","description":"Description","start_url":"/","icon":"src/images/icon.png","background_color":"#18191a","theme_color":"#18191a","display":"fullscreen","theme_color_in_head":false,"crossOrigin":"use-credentials","orientation":"portrait","lang":"de","categories":["food"],"dir":"ltr","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"6d3a35e6eaa2d4c040268fce30bbae1c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.rossinipizzeria.de"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
